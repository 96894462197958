import React, { useState, useEffect } from 'react';
import './styles/EventHubSources.css'
import '../../pages/styles/pages.css'
import PageHeader from '../../components/PageHeader/PageHeader'; 
import Button from '../../components/Button/Button'; 
import FallbackMessage from '../../components/FallbackMessage/FallbackMessage';
import EventHubSourceSidePanel from '../../components/EventHubSourceSidePanel/EventHubSourceSidePanel'; 
import { useApi } from '../../contexts/ApiProvider';

const SidePanel = ({ isOpen, togglePanel }) => (
    <div className={`side-panel ${isOpen ? 'open' : ''}`}>
      <EventHubSourceSidePanel togglePanel={togglePanel}/>
    </div>
);

const FallbackView = () => (
    <FallbackMessage icon={'database'} subtext={'Add a source to start managing your data'}>
            No sources in this account
    </FallbackMessage>
);

const TableRow = ({ item }) => (
    <tr>
        <td> 
            {item.SourceID} {/* Adjust this if you have a specific source mapping */}
        </td>
        <td>{item.AccountName}</td>
        <td>{item.AccountID}</td>
        <td><div className='status'>Connected</div></td> {/* You might want to implement real status logic */}
    </tr>
);

const SourcesList = ({ data }) => (
    <div className="sources-list-container">
        <table>
            <thead>
                <tr>
                    <th>Source</th>
                    <th>Account Name</th>
                    <th>Account ID</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <TableRow key={item.ClientSourceID} item={item} />
                ))}
            </tbody>
        </table>
    </div>
);

const EventHubSources = () => { 
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [sourcesData, setSourcesData] = useState([]);
    const { apiRequest, isLoading } = useApi();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiRequest({
                    action: 'getClientSources',
                    method: 'get',
                });
                setSourcesData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const togglePanel = () => {
        setPanelOpen(prevState => !prevState);
    };

    return (
        <>
          <div className='header-compartiment'>
            <PageHeader title={"Sources"}>Connect various sources of data for enhanced reporting.</PageHeader>
            <Button icon={'faPlus'} onClick={togglePanel}>Add Source</Button>
          </div>
  
          {sourcesData.length > 0 ? (
            <SourcesList data={sourcesData} />
          ) : (
            <FallbackView />
          )}
  
          <div className={`overlay ${isPanelOpen ? 'show' : ''}`} onClick={togglePanel} />
  
          <SidePanel isOpen={isPanelOpen} togglePanel={togglePanel} />
        </>
    );
};

export default EventHubSources;