import React from 'react';
import './styles/Home.css'
import '../../pages/styles/pages.css'
import CardBox from '../../components/CardBox/CardBox';
import PageHeader from '../../components/PageHeader/PageHeader'; 
import useStore from '../../store';

const Home = () => {
  const userInfo = useStore((state) => state.user);

  return (

    <div className='content'>
      <PageHeader 
          title={`Good Evening, ${userInfo.FirstName}!`}
        >
         Unlock the power of real-time insights with Sociax, your all-in-one platform for integrating ad accounts from networks like Facebook, Google, and more. Make informed decisions with our comprehensive reporting and AI-driven recommendations.
      </PageHeader>

    <div className='wrap-cards'>
    <CardBox title='Incremental Revenue'>
      Easily connect your ad accounts from various networks like Facebook, Google, and others. Access all your advertising data in one place and streamline your workflow for optimal efficiency.
    </CardBox>

    <CardBox title='Real-time Performance'>
      Stay informed with instant notifications if any issues arise with your ad campaigns. Sociax proactively monitors your ads, allowing you to address problems quickly and keep your campaigns on track.
    </CardBox>

    <CardBox title='Keyword Planner'>
      Harness the power of AI for smart bidding strategies. Our advanced AI algorithms analyze your ad performance data to provide you with actionable recommendations, optimizing your ad spend and maximizing ROI.
    </CardBox>

    </div>
</div>

  );
};

export default Home