// src/pages/Login/Login.js
import React, { useState, useEffect } from "react";
import "./styles/Login.css";
import SVGIcons from "../../components/SVGIcons/SVGIcons";
import { GoogleLogin } from '@react-oauth/google';
import useStore from '../../store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useApi } from '../../contexts/ApiProvider';

const SVGIcon = () => (
  <svg width="40" height="35" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="35" fill="#1E2630" />
    <rect x="5" y="7" width="12" height="2" fill="rgb(37,183,84)" />
    <rect x="19" y="12" width="2" height="15" fill="rgb(37,183,84)" />
  </svg>
);

function Login() {
  const setAuthorization = useStore((state) => state.setAuthorization);
  const setToken = useStore((state) => state.setToken);
  const setUser = useStore((state) => state.setUser);
  const setClients = useStore((state) => state.setClients);
  const { apiRequest, isLoading } = useApi();

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [svgPositions, setSvgPositions] = useState([]);

  useEffect(() => {
    const count = 100;
    const positions = Array.from({ length: count }, () => ({
      top: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`
    }));
    setSvgPositions(positions);
  }, []);

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;
    
    const deltaX = clientX - centerX;
    const deltaY = clientY - centerY;
    
    const dampeningFactor = 50;
    
    const scaledX = deltaX / dampeningFactor;
    const scaledY = deltaY / dampeningFactor;
    
    const cappedX = Math.max(-15, Math.min(15, scaledX));
    const cappedY = Math.max(-15, Math.min(15, scaledY));
    
    setPosition({ x: cappedX, y: cappedY });
  };

  const handleSuccess = (response) => {
    fetch('https://prod.sociax.com.br/services/platform/v1/users/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(response),
    })
    
    .then(res => res.json())
    .then(data => {
        if (data.token) {
            sessionStorage.setItem("authToken", data.token);
            sessionStorage.setItem("userData", JSON.stringify(data));

            setToken(data.token);
            setUser(data);
            setAuthorization(true);
        } else {
            console.error('Authentication failed:', data);
        }
    })
    .catch(error => console.error('Error:', error));
};

  const handleError = () => {
    console.log('Login Failed');
  };

  return (
    <div className="main-login-container" onMouseMove={handleMouseMove}>
      <div className="plus-icon-container" style={{ transform: `translate(${position.x}px, ${position.y}px)` }}>
        {svgPositions.map((pos, index) => (
          <div key={index} className="svg-background" style={{ top: pos.top, left: pos.left }}>
            <SVGIcon />
          </div>
        ))}
      </div>
      <div className="login-external-box">
        <div className="login-sidebar">
          <div className="login-sidebar-logo">
            <SVGIcons name={`logo_login`} />
          </div>
          <div className="login-networks-buttons">
          <GoogleOAuthProvider clientId="38238855150-lmln2jdamqkvi0t2tu4h9bl009j4ceef.apps.googleusercontent.com">
            <GoogleLogin 
              onSuccess={handleSuccess}
              onError={handleError}
              size="large"
              shape="pill"
            />
          </GoogleOAuthProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;