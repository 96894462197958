import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import './styles/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icons from '@fortawesome/free-solid-svg-icons';
import SVGIcons from '../SVGIcons';

const menuData = [
  {
    name: 'Home',
    route: '/home',
    icon: 'faHome',
    submenu: []
  },
  {
    name: 'Analytics',
    route: '/analytics',
    icon: 'faChartSimple',
    submenu: [
      { name: 'Compass', route: '/analytics/compass', icon: 'faCompass' },
      { name: 'Intraday', route: '/analytics/intraday', icon: 'faClock' },
    ]
  },
  {
    name: 'Event Hub',
    route: '/event-hub',
    icon: 'faSatelliteDish',
    submenu: [
      { name: 'Sources', route: '/event-hub/sources', icon: 'faDatabase' },
      { name: 'Destinations', route: '/event-hub/destinations', icon: 'faShip' },
      { name: 'Funnels', route: '/event-hub/funnels', icon: 'faFilter' },
      { name: 'Event Match', route: '/event-hub/event-match', icon: 'faPuzzlePiece' }
    ]
  },
  {
    name: 'Planning',
    route: '/planning',
    icon: 'faClipboardList',
    submenu: [
      { name: 'Insights', route: '/planning/insights', icon: 'faLightbulb' },
      { name: 'Activation', route: '/planning/activation', icon: 'faPowerOff' },
      { name: 'Creative Sprints', route: '/planning/creative-sprints', icon: 'faRunning' }
    ]
  },
  {
    name: 'Settings',
    route: '/settings',
    icon: 'faCog',
    submenu: [
      { name: 'Account Info', route: '/settings/account-info', icon: 'faUserCircle' },
      { name: 'Exit', route: '/settings/exit', icon: 'faSignOutAlt' }
    ]
  }
];



const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState('/home');
  const [hovered, setHovered] = useState(null);

  useEffect(() => {
      const path = location.pathname;
      const activeItem = menuData.find(item => 
          item.route === path || item.submenu.some(sub => path.includes(sub.route))
      );
      if (activeItem) {
          setActive(activeItem.route);
      }
  }, [location]);

  const getIcon = (iconName) => {
      return Icons[iconName];
  };

  const onClickHandler = (route, event) => {
      if (event) {
          event.stopPropagation();
      }
      navigate(route);
  };

  return (
      <div className="header">
          <div className="header-container">
              <nav className="header-nav">
                  <div className='main-icon'>
                      <SVGIcons name={'logo'} />
                  </div>
                  <ul>
                      {menuData.map(item => (
                          <li key={item.route}
                              className={active.includes(item.route) ? 'active' : ''}
                              onClick={() => onClickHandler(item.route)}
                              onMouseEnter={() => setHovered(item.route)}
                              onMouseLeave={() => setHovered(null)}
                          >
                              <FontAwesomeIcon icon={getIcon(item.icon)} className="header-icon"/>
                              {hovered === item.route && item.submenu.length > 0 && (
                                  <div className="sub-menu">
                                      <div className="sub-menu-title">{item.name}</div>
                                      {item.submenu.map(sub => (
                                          <div key={sub.route} onClick={(e) => onClickHandler(sub.route, e)}>
                                              <FontAwesomeIcon icon={getIcon(sub.icon)} className="sub-menu-icon"/>
                                              {sub.name}
                                          </div>
                                      ))}
                                  </div>
                              )}
                          </li>
                      ))}
                  </ul>
              </nav>
          </div>
      </div>
  );
}

export default Header;