// src/store.js
import create from 'zustand';

const useStore = create((set) => ({
    isAuthorized: false,
    token: null,
    user: null,

    setAuthorization: (isAuthorized) => set({ isAuthorized }),
    setToken: (token) => set({ token }),
    setUser: (user) => set({ user }),
}));

export default useStore;