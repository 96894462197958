import React, { useState, useRef, useEffect } from 'react';
import './styles/HeaderButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import useStore from '../../store'

const getIcon = (iconName) => {
    return Icons[iconName];
};


const HeaderButton = ({ subtitle, icon, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const userData = useStore((state) => state.user);
    
    const dropdownData = userData ? userData.clients.map(client => ({
        id: client.ClientID,
        title: `${client.FantasiaName}`,
        subtitle: client.CNPJNumber,
        icon: "faBuilding" // default icon, you can customize this as needed
    })) : [];

    const toggleTab = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]); // Empty dependency array means this effect will only run once, similar to componentDidMount

    return (
        <div ref={ref} className='header-button-container'>
            <div className='header-button' onClick={toggleTab}>
                <div className='icon'>
                    <FontAwesomeIcon icon={getIcon(icon)} />
                </div>
                <div className='text-content'>
                    <p>{children}</p>
                    <span className='channel-info'>{subtitle}</span>
                </div>
                <div className='icon-checkmark'>
                    <FontAwesomeIcon icon={getIcon('faArrowDown')} className={`checkmark ${isOpen ? 'open' : ''}`} />
                </div>
            </div>
            {isOpen && (
                <div className='dropdown-tab'>
                    {dropdownData.map(item => (
                        <div className='dropdown-item' key={item.id}>
                            <FontAwesomeIcon icon={getIcon(item.icon)} className='dropdown-icon' />
                            <div className='dropdown-text'>
                                <p>{item.title}</p>
                                <span>{item.subtitle}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default HeaderButton;